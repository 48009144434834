<template>
    <div class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">
        <div v-if="user" class="bg-white p-4">
            <h3>User Permissions</h3>
            <h5>{{ user.name }}</h5>


            <h4>Permissions</h4>

            <form @submit.prevent="savePermissions()">
                <table class="table">
                    <thead>
                    <tr>
                        <th>SN</th>
                        <th>Name</th>
                        <th>Permission</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(i, index) in permissions" :key="i.id">
                        <td>{{ ++index }}</td>
                        <td>{{ i.narration }}</td>
                        <td>
                            <input type="checkbox" :value="i.id" v-model="selected">
                        </td>
                    </tr>
                    </tbody>
                </table>

                <button type="submit" class="btn btn-primary">Save</button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: "Permissions",
    props: ['id'],
    data() {
        return {
            user: null,
            permissions: [],

            selected: []
        }
    },
    mounted() {
        this.loadUser()
    },
    methods: {
        // loadUser() {
        //     this.$http.get(`/api/users/manage/${this.id}`).then(response => {
        //         this.user = response.data.data.user
        //         this.permissions = response.data.data.permissions
        //
        //         this.selected =  this.permissions.filter(a => a.allocated == true).map(a => a.id)
        //     })
        // },
        savePermissions() {
            this.$http.post('/api/users/permissions', {id: this.id, permissions: this.selected}).then(() => {

            })
        }
    }
}
</script>

<style scoped>

</style>